
const BASE_URL = process.env.REACT_APP_API_URL;
//const BASE_URL = 'http://api.lvh.me:80/api/v1';

export function apiFetch(path, options = {}) {
  const allOptions = {
    headers: new Headers({
      'Authorization': localStorage.getItem('auth-header'),
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    ...options
  };

  return fetch(BASE_URL + path, allOptions)
    .catch(err => {
      console.log('Fetch Error:', err);
    });
}

export function apiNoAuthFetch(path, options = {}) {
  return fetch(BASE_URL + path, options)
    .catch(err => {
      console.log('Fetch Error:', err);
    });
}
