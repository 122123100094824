import React from 'react';
import { apiFetch } from './api.js'
import './Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: 'developers+testcases@wavedigital.com.au',
      password: '',
      message: ''
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  login = (event) => {
    const body = JSON.stringify({
      user: {
        email: this.state.email,
        password: this.state.password
      }
    });

    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: body,
    };

    apiFetch('/login', options)
      .then(response => {
          if(response) {
            if (response.status === 201) {
              localStorage.setItem('auth-header', response.headers.get('authorization'));
              this.props.onSuccess();
            } else {
              response.json().then(data => {
                this.setState({message: data.error});
              });
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }
          } else {
            alert('Could not login');
          }
        }
      );
  };

  render() {
    const { email, password, message } = this.state;
    return (<div className="card">
      <h1>Login</h1>
      <label>Email:
      <input
        name="email"
        value={email}
        onChange={this.handleChange}
        size={50}
        />
      </label><br/>
      <label>Password:
        <input
          type="password"
          name="password"
          value={password}
          onChange={this.handleChange}
          />
      </label>
      <button onClick={this.login}>Login</button>
      <div>{message}</div>
    </div>);
  }
}

export default Login;
