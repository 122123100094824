const handleFocus = event => event.target.select();

export default function MatingDetails(props) {
  const { mating, animals, onChange, onSave } = props;
  const bitches = animals.filter(a => a.sex === 'female');
  const dogs = animals.filter(a => a.sex === 'male');

  return (
    <div>
      #{mating.id} <  br/>

      <label>
        Remark:
        <input
          name="remark"
          value={mating.remark === null ? '' : mating.remark}
          onChange={event => onChange(event)}
          onFocus={handleFocus}
          autoComplete="off"
          size={50}
          />
      </label>

      <label>
        Bitch:
        <select name="bitch_id" value={mating.bitch_id ? mating.bitch_id : ""} onChange={event => onChange(event)}>
          <option value=""></option>
          { bitches.map((animal, index) => <option key={index} value={animal.id}>{animal.registered_name}</option>) }
        </select>
      </label>

      <label>
        Dog:
        <select name="dog_id" value={mating.dog_id ? mating.dog_id : ""} onChange={event => onChange(event)}>
          <option value=""></option>
          { dogs.map((animal, index) => <option key={index} value={animal.id}>{animal.registered_name}</option>) }
        </select>
      </label>

      <label>
        Mating Type:
        <select name="mating_type" value={mating.mating_type ? mating.mating_type : ""} onChange={event => onChange(event)}>
          <option value=""></option>
          <option value="natural_ai">natural_ai</option>
          <option value="transcervical_ai">transcervical_ai</option>
          <option value="surgical_ai">surgical_ai</option>
        </select>
      </label>

      <label>
        Mating Type:
        <select name="semen_type" value={mating.semen_type ? mating.semen_type : ""} onChange={event => onChange(event)}>
          <option value=""></option>
          <option value="fresh">fresh</option>
          <option value="chilled">chilled</option>
          <option value="frozen">frozen</option>
        </select>
      </label>

      <label>
        # matings
        <input
          name="num_matings"
          value={mating.num_matings === null ? '' : mating.num_matings}
          onChange={event => onChange(event)}
          onFocus={handleFocus}
          autoComplete="off"
          />
      </label>

      <button onClick={onSave}>Save Mating Details</button>
    </div>
  );
}
