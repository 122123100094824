
const handleFocus = event => event.target.select();

export default function ReadingRow(props) {
  const { reading, dateDone, enteredDone, index, handleBlur, handleChange, gotoDate, toggleEntered, onDelete } = props;
  const dateIsDoneClass = dateDone ? 'date-done' : '';
  const valueEnteredClass = enteredDone ? 'date-done' : '';
  return (
    <tr>
      <td className={valueEnteredClass}>
        <button data-index={index} onClick={onDelete}>X</button>
      </td>
      <td className={dateIsDoneClass}>
        <input
          name="date"
          value={reading.date === null ? '' : reading.date}
          onChange={event => handleChange(event)}
          onBlur={handleBlur}
          data-index={index}
          onFocus={handleFocus}
          autoComplete="off"
          />
      </td>
      <td className={dateIsDoneClass}>
        <input
          name="reading_no"
          value={reading.reading_no === null ? '' : reading.reading_no}
          onChange={event => handleChange(event)}
          onBlur={handleBlur}
          data-index={index}
          onFocus={handleFocus}
          autoComplete="off"
          size="2"
          />
      </td>
      <td className={dateIsDoneClass}>
        <button data-index={index} onClick={gotoDate}>Goto</button>
      </td>
      <td className={valueEnteredClass}>
        <input
          name="value"
          value={reading.value === null ? '' : reading.value}
          onChange={event => handleChange(event)}
          onBlur={handleBlur} data-index={index}
          onFocus={handleFocus}
          autoComplete="off"
          />
      </td>
      <td className={valueEnteredClass}>
        <button data-index={index} onClick={toggleEntered}>{reading.entered ? 'Undo' : 'Calculate' }</button>
      </td>
    </tr>
  );
}
