import React from 'react';
import './Calculator.css';
import ReadingRow from './ReadingRow.js'
import MatingDetails from './MatingDetails.js'
import { apiFetch } from './api.js'

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: 'DEBUG OUTPUT',
      rating_percent: null,
      flags: [],
      dateIndex: -1,
      enteredIndex: -1,
      readings: [],
      mating: null,
      matings: [],
      dirty: false,
      animals: []
    };
  }

  componentDidMount() {
    this.loadMatings();
    this.loadAnimals();
  }

  resetDates = () => {
    this.setState({
      dateIndex: -1,
      enteredIndex: -1,
    });
  };

  addReading = () => {
    const readings = this.state.readings;
    const date = readings.length >= 1 ? this.tomorrow(readings[readings.length-1].date) : new Date().toISOString().slice(0, 10);
    this.setState({
      readings: [...readings, {
        date: date,
        reading_no: 1,
        value: null,
        unit: 'ng',
        machine_type: 'vidas',
        dirty: true
      }]
    });
  };

  saveReading = (reading, index) => {
    const matingId = this.state.mating.id;
    const body = JSON.stringify({ progesterone_reading: reading });

    const options = {
      method: (reading.id ? 'PUT' : 'POST'),
      body: body
    };

    return apiFetch('/matings/' + matingId + '/progesterone_readings' + (reading.id ? ('/' + reading.id) : ''), options)
      .then(response => {
          if (response.status !== 201 || response.status !== 200) {
            response.json().then(data => {
              this.setReadingState(index, 'id', data.data.id);
            });
          } else {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            response.json().then(data => {
              this.setState({ debug: JSON.stringify(data) });
            });
            return;
          }
        }
      );
  }

  deleteReading = (reading) => {
    const matingId = this.state.mating.id;

    const options = { method: 'DELETE' };

    return apiFetch('/matings/' + matingId + '/progesterone_readings/' + (reading.id ? ('/' + reading.id) : ''), options)
      .then(response => {
          if (response.status !== 204) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            response.json().then(data => {
              this.setState({ debug: JSON.stringify(data) });
            });
            return;
          }
        }
      );
  }

  loadMating = (id) => {
    apiFetch('/matings/' + id)
      .then(response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response.json().then(data => {
            this.setState({
              mating: data.data,
              readings: data.data.progesterone_readings
            });
          });
        }
      );
  }

  loadMatings = () => {
    apiFetch('/matings')
      .then(response => {
          if(response) {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }
            response.json().then(data => {
              this.setState({
                matings: data.data,
                mating: data.data[0],
                readings: data.data[0].progesterone_readings
              });
            });
          }
        }
      );
  }

  loadAnimals = () => {
    apiFetch('/animals')
      .then(response => {
          if(response) {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }
            response.json().then(data => {
              this.setState({
                animals: data.data,
              });
            });
          }
        }
      );
  }

  selectMating = (event) => {
    const id = parseInt(event.target.getAttribute("data-id"));
    this.loadMating(id);
  };

  filteredReadings = () => {
    return this.state.readings.filter((reading, index) => index <= this.state.enteredIndex);
  }

  simulatedDate = () => {
    const reading = this.state.readings.find((reading, index) => (index === this.state.dateIndex));
    return reading.date;
  }

  toggleEntered = (event) => {
    const i = parseInt(event.target.getAttribute("data-index"));
    this.setState({
      enteredIndex: i,
      dateIndex: i
    }, () => this.calculate());
  };

  handleReadingDelete = (event) => {
    const i = parseInt(event.target.getAttribute("data-index"));
    let readings = [...this.state.readings];
    const reading = readings[i];
    readings.splice(i, 1);
    this.setState({readings: readings});
    if(reading.id) {
      this.deleteReading(reading);
    }
  }

  gotoDate = (event) => {
    const i = parseInt(event.target.getAttribute("data-index"));
    this.setState({
      dateIndex: i,
      enteredIndex: i - 1
    }, () => this.calculate());
  };

  handleMatingChange = (event) => {
    let mating = {...this.state.mating};
    mating[event.target.name] = event.target.value;
    this.setState({mating: mating});
  };

  handleChange = (event) => {
    const i = parseInt(event.target.getAttribute("data-index"));
    this.setReadingState(i, event.target.name, event.target.value);
  };

  setReadingState = (index, property, value) => {
    let readings = [...this.state.readings];
    let reading = {...readings[index]};
    reading[property] = value;
    readings[index] = reading;
    this.setState({readings: readings, dirty: true});
  }

  handleBlur = (event) => {
    const i = parseInt(event.target.getAttribute("data-index"));
    let readings = [...this.state.readings];
    let reading = {...readings[i]};
    reading.value = reading.value ? parseFloat(reading.value) : null;
    reading.reading_no = reading.reading_no ? parseFloat(reading.reading_no) : null;
    readings[i] = reading;

    if(this.state.dirty) {
      this.saveReading(reading, i);
    }
    this.setState({readings: readings, dirty: false});
  };

  calculate = () => {
    const simulatedDate = this.simulatedDate();
    const filteredReadings = this.filteredReadings();
    const lastReading = filteredReadings[filteredReadings.length - 1];
    const readingNo = ((this.state.dateIndex === this.state.enteredIndex) && lastReading) ? lastReading.reading_no : null;
    this.setState({simulatedDate: simulatedDate, readingNo: readingNo});

    apiFetch('/matings/' + this.state.mating.id + '/progesterone_result?simulation=1&simulated_date=' + simulatedDate + '&simulated_reading_no=' + readingNo)
      .then(response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            response.json().then(data => {
              this.setState({ debug: JSON.stringify(data) });
            });
            return;
          }
          response.json().then(data => {
            this.setState({
              debug: data.data.debug,
              rating_percent: data.data.rating_percent,
              flags: data.data.flags || []
            });
          });
        }
      );
  };

  newMating = () => {
    const mating = {
      num_matings: 1,
      remark: ''
    }

    this.setState({
      mating: mating,
      debug: 'New mating',
      rating_percent: null,
      flags: [],
      dateIndex: -1,
      enteredIndex: -1,
      readings: [],
    });
  }

  saveMating = () => {
    const id = this.state.mating.id;
    const body = JSON.stringify({ mating: this.state.mating });

    const options = {
      method: (id ? 'PUT' : 'POST'),
      body: body
    };

    apiFetch('/matings' + (id ? ('/' + id) : ''), options)
      .then(response => {
          if (response.status !== 201) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            response.json().then(data => {
              this.setState({ debug: JSON.stringify(data) });
            });
            return;
          }
          response.json().then(data => {
            this.setState({
              mating: data.data,
              matings: [...this.state.matings, data.data],
              debug: 'Saved',
              rating_percent: null,
              flags: [],
              dateIndex: -1,
              enteredIndex: -1,
              readings: [],
            });
          });
        }
      );
  };

  yesterday = (dateString) => {
    var date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString().slice(0, 10);
  };

  tomorrow = (dateString) => {
    var date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().slice(0, 10);
  };

  render() {
    const {
      readings, debug, rating_percent, flags,
      dateIndex, enteredIndex,
      simulatedDate, readingNo,
      mating, matings, animals
    } = this.state;

    const renderMatings = (
      <div>
        { matings.map((mating, index) => (
          <button key={index} data-id={mating.id} onClick={this.selectMating}>#{mating.id}</button>
        )) }
        <button onClick={this.newMating}>New Mating</button>
      </div>
    );

    const renderMatingDetails = mating && (
      <div className="card readings-box">
        <MatingDetails mating={mating} animals={animals} onChange={this.handleMatingChange} onSave={this.saveMating}/>
      </div>
    );

    const renderReadings = mating && (
      <div className="card readings-box">
        <table className="readings">
          <thead>
            <tr>
              <th></th>
              <th>Date</th>
              <th>#</th>
              <th></th>
              <th>Reading (ng)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          { readings.map((reading, index) => (<ReadingRow
                                                key={index}
                                                reading={reading}
                                                index={index}
                                                dateDone={index <= dateIndex}
                                                enteredDone={index <= enteredIndex}
                                                handleBlur={this.handleBlur}
                                                handleChange={this.handleChange}
                                                gotoDate={this.gotoDate}
                                                toggleEntered={this.toggleEntered}
                                                onDelete={this.handleReadingDelete}
                                              />)) }
          </tbody>
        </table>
        <button onClick={this.addReading}>Add Reading</button>
      </div>
    );

    const renderRating = (
      <div className="rating card">
        <div>Date: {simulatedDate} Reading#: {readingNo}</div>
        <div>Progesterone Cycle Rating</div>
        <h1>{rating_percent || '--'}%</h1>
      </div>
    );

    const renderFlags = (
      <div className="flags card">
        <ul>
        {
          flags.map((flag, index) => (
            <li key={index} className={'flag-' + flag.flag_type} >
              {flag.message} {flag.overdue && <span className="overdue">overdue</span>}
            </li>
          ))
        }
        </ul>
      </div>
    );

    const renderDebug = (
      <div className="debug card">
        <pre>
          {debug}
        </pre>
      </div>
    );

    return (
      <div className="app">
        <div className="row-container">
          {renderMatings}
        </div>
        <div className="row-container">
          <div className="col-container">
            {renderMatingDetails}
            {renderReadings}
          </div>
          <div className="col-container">
            {renderRating}
            {renderFlags}
          </div>
        </div>
        {renderDebug}
      </div>
    );
  }
}

export default Calculator;
