import React from 'react';
import Login from './Login';
import Calculator from './Calculator';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: !!localStorage.getItem('auth-header')
    };
  }

  onLogin = () => {
    console.log('onLogin');
    this.setState({ signedIn: true });
  };

  logout = () => {
    localStorage.removeItem('auth-header');
    this.setState({ signedIn: false });
  };

  render() {
    const { signedIn } = this.state;
    return (
      signedIn ?
        <div>
          <Calculator/>
          <div>
            <button onClick={this.logout}>Logout</button>
          </div>
        </div>
      :
        <Login onSuccess={this.onLogin} />
    );
  }
}

export default App;
